/* eslint-disable */
import React from "react"
import { Box } from '@chakra-ui/react'
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Blocks from '../Blocks/RecursiveBlockRenderer'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

const isJsonString = ( str ) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const PagePost = ({ data, pageContext }) => {
  const { page, images } = data
  const { featuredImage } = page
  const {
    breadcrumb: { crumbs },
  } = pageContext
  const crumbsUnslugged = crumbs.map((crumb) => { // Unsluggify breadcrumbs
    return {
      // pathname: crumb.pathname,
      pathname: crumb.pathname.replace(/^\/residential$/g, 'https://www.mtasolutions.com/'),
      crumbLabel: crumb.crumbLabel.replace(/\-/g, ' ')
      .replace(/\w\S*/g, (txt) => { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase() })
      .replace(/Mta/g, (txt) => { return txt.toUpperCase() })
      .replace(/Ebill/g, 'eBill')
      .replace(/Rfps/g, 'RFPs')
    }
  })
  crumbsUnslugged[crumbsUnslugged.length - 1].crumbLabel = data.page.title

  return (
    <Layout heroContent={page.heroContent} page={page}>
      {crumbs.length > 2 && page?.template?.templateName !== 'Landing Pages' && page?.template?.templateName !== 'Landing Pages With Footer' &&
        <Box className="container" zIndex={1000} position="relative" pl="30px">
          <Breadcrumb crumbs={crumbsUnslugged} crumbSeparator=" » " hiddenCrumbs={['/']} />
        </Box>
      }

      {!!featuredImage?.node?.remoteFile?.childImageSharp && (
        <Box mb={5} className="container">
          {/* <Img fluid={featuredImage.node.localFile.childImageSharp.fluid} /> */}
          {featuredImage.node.ext !== '.svg' ? (
            <GatsbyImage image={featuredImage.node.localFile.childImageSharp.gatsbyImageData} alt={featuredImage.node.altText} />
          ) : (
            <img src={featuredImage.node.localFile.childImageSharp.gatsbyImageData} alt={featuredImage.node.altText} />
          )}
        </Box>
      )}

      <div className="blocksWrapper">
        { isJsonString(data.page.blocksJSON) &&
          <Blocks page={page} images={images} blocks={JSON.parse(data.page.blocksJSON)} dynamicBlocks={data.page.blocks} />
        }
      </div>
    </Layout>
  )
}

export default PagePost
